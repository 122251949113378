<template>
<svg :width="width" :height="height" :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" d="M17,5 C19.7614237,5 22,7.23857625 22,10 C22,12.7614237 19.7614237,15 17,15 L9,15 C7.34314575,15 6,13.6568542 6,12 C6,10.3431458 7.34314575,9 9,9 L17,9 C17.5522847,9 18,9.44771525 18,10 C18,10.5522847 17.5522847,11 17,11 L9,11 L8.88337887,11.0067277 C8.38604019,11.0644928 8,11.4871642 8,12 C8,12.5128358 8.38604019,12.9355072 8.88337887,12.9932723 L9,13 L17,13 C18.6568542,13 20,11.6568542 20,10 C20,8.34314575 18.6568542,7 17,7 L9,7 C6.23857625,7 4,9.23857625 4,12 C4,14.6887547 6.12230671,16.8818181 8.78311038,16.9953805 L9,17 L17,17 C17.5522847,17 18,17.4477153 18,18 C18,18.5522847 17.5522847,19 17,19 L9,19 C5.13400675,19 2,15.8659932 2,12 C2,8.13400675 5.13400675,5 9,5 L17,5 Z"/>
</svg>
</template>
<script>
export default {
  name: 'ascIconSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
